import { ReportMenuItem } from './report-menu-item.type';

export const REPORT_MENU_ITEMS: ReportMenuItem[] = [
	{
		code: 'general',
		title: 'Основные показатели',
		children: [],
	},
	{
		code: 'advertising',
		title: 'Реклама',
		children: [
			{
				code: 'adv-general',
				title: 'Основные показатели',
				children: [],
			},
			{
				code: 'adv-compare',
				title: 'Сравнение',
				children: [],
			},
		],
	},
	{
		title: 'Направления',
		code: 'directions',
		children: [
			{
				code: 'zagorodka',
				title: 'Загородка',
				children: [],
			},
			{
				code: 'novostroyki',
				title: 'Новостройки',
				children: [],
			},
			{
				code: 'vtorichka',
				title: 'Вторичка',
				children: [],
			},
			{
				code: 'ipoteka',
				title: 'Ипотека',
				children: [],
			},
			{
				code: 'commercial',
				title: 'Коммерческая',
				children: [],
			},
			{
				code: 'hr',
				title: 'HR',
				children: [],
			},
		],
	},
	{
		code: 'aggregators',
		title: 'Агрегаторы',
		children: [
			{
				code: 'ag-summary',
				title: 'Сводная',
				children: [],
			},
			{
				code: 'ag-objects',
				title: 'Объекты',
				children: [],
			},
			{
				code: 'ag-rieltors',
				title: 'Риелторы',
				children: [],
			},
		],
	},
	{
		code: 'smm',
		title: 'SMM',
		children: [],
	},
	{
		code: 'clients',
		title: 'Клиенты',
		children: [],
	},
	{
		code: 'etagicom',
		title: 'Etagi.com',
		children: [],
	},
	{
		code: 'saved-filters',
		title: 'Сохраненные фильтры',
		children: [],
	},
];
