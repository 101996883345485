export const REPORTS: { name: string; title: string }[] = [
	{
		name: 'general',
		title: 'Основные показатели',
	},
	{
		name: 'advertising',
		title: 'Реклама - Основные показатели',
	},
	{
		name: 'adv-compare',
		title: 'Реклама - Сравнение',
	},
	{
		name: 'zagorodka',
		title: 'Направления - Загородка',
	},
	{
		name: 'novostroyki',
		title: 'Направления - Новостройки',
	},
	{
		name: 'vtorichka',
		title: 'Направления - Вторичка',
	},
	{
		name: 'ipoteka',
		title: 'Направления - Ипотека',
	},
	{
		name: 'commercial',
		title: 'Направления - Коммерческая',
	},
	{
		name: 'hr',
		title: 'Направления - HR',
	},
	{
		name: 'smm',
		title: 'SMM',
	},
	{
		name: 'ag-summary',
		title: 'Агрегаторы - Сводная',
	},
	{
		name: 'ag-objects',
		title: 'Агрегаторы - Объекты',
	},
	{
		name: 'ag-rieltors',
		title: 'Агрегаторы - Риелторы',
	},
	{
		name: 'etagi-pro',
		title: 'Etagi Pro - Основные показатели',
	},
	{
		name: 'etagi-pro-summary',
		title: 'Etagi Pro - Сводная',
	},
	{
		name: 'etagi-pro-ext',
		title: 'Etagi Pro - Внешний',
	},
	{
		name: 'etagi-pro-buhg',
		title: 'Etagi Pro - Бухгалтерия',
	},
	{
		name: 'markbot',
		title: 'Опросы',
	},
	{
		name: 'markbot-dubai',
		title: 'Опросы Dubai',
	},
	{
		name: 'etagicom',
		title: 'Etagi.com',
	},
	{
		name: 'clients',
		title: 'Клиенты',
	},
];
